import { cssVariables } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import { useRef } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import { useSignal } from '../hooks/use-signal'
import { Grid } from './Grid'

// eslint-disable-next-line import/no-extraneous-dependencies
import '@ubnt/webfonts/ui-sans-400.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@ubnt/webfonts/ui-sans-700.css'

const customStyledProps: Record<string, boolean> = {
  full: true,
  column: true,
  center: true,
}

export const shouldForwardProp = (props: string) => {
  return !customStyledProps[props]
}

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex: 1;
`

export const GlobalStyle = createGlobalStyle`
  body {
    line-height: 1.6;
    font-size: ${cssVariables['font-size-body']};
    color: ${cssVariables.motifs.light.text02};
    -webkit-font-smoothing: antialiased;

    > div[data-testid="DropdownRoot"] ul {
      z-index: 1001; // so that Dropdown with portal prop shows on top of a modal
    }
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`

export const RouteContent = styled(Grid)`
  flex-basis: 1%;
`

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`

export const DashboardContainer = styled(Grid)`
  padding: 28px 56px;
  margin: 0 !important;
  flex: 0;
`

export const MediaContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export function ScrollContainer({ children }: { children: ReactNode }) {
  const containerRef = useRef<HTMLDivElement>(null)

  useSignal('scroll-to-top', () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  })

  return <ScrollContainerDiv ref={containerRef}>{children}</ScrollContainerDiv>
}

const ScrollContainerDiv = styled.div`
  position: relative;
  overflow: auto;
  flex: 1;
`

interface ScrollContentProps {
  $inline?: boolean
}
export const ScrollContent = styled.div<ScrollContentProps>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$inline &&
    css`
      min-height: initial;
      height: 100%;
    `}
`

interface VerticalProps {
  $flex?: boolean
  $centerH?: boolean
  $centerV?: boolean
  $height?: number
  $gap?: number
}

export const Vertical = styled.div<VerticalProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$flex &&
    css`
      flex: 1;
      height: 100%;
    `}

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `}

  ${(props) =>
    props.$centerH &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.$centerV &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.$gap &&
    css`
      & > * {
        margin-bottom: ${props.$gap}px;

        :last-child {
          margin-bottom: 0;
        }
      }
    `}
`

export const ItemContainer = styled.div`
  & > * {
    margin-bottom: 12px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`
