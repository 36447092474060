import type { CountryCode } from 'libphonenumber-js'
import { EuropeanUnionCountries } from '../../lib/constants'

export const uCareSupportedCountries: Set<CountryCode> = new Set([
  'US',
  'CA',
  'GB',
  'NO',
  'CH',
  'AU',
  ...EuropeanUnionCountries,
])

export const modifiedUCareUnsupportedCountries: Set<CountryCode> = new Set(['AU'])

export function doesCountrySupportUCare(countryStr?: CountryCode | string, modifiedUCare?: boolean) {
  if (!countryStr) {
    return false
  }

  const isSupported = uCareSupportedCountries.has(countryStr as CountryCode)
  if (!isSupported) {
    return false
  }

  if (modifiedUCare && modifiedUCareUnsupportedCountries.has(countryStr as CountryCode)) {
    return false
  }

  return true
}
