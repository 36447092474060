import type { TicketStatusData } from '../types/tickets'
import type { FlowType } from './constants'
import {
  TICKET_CUSTOMER_NOTES_MAX_LENGTH,
  TICKET_FAILURE_DESCRIPTION_MAX_LENGTH,
  TICKET_SHIPPING_COMPANY_MAX_LENGTH,
  TICKET_TRACKING_NUMBER_MAX_LENGTH,
  TicketStatus,
  TicketStatusFlow,
} from './constants'

export type ValidationState = Record<string, unknown>

type ValidationFunction = (value?: unknown) => boolean

function isRequired(value?: unknown) {
  return !!value
}

const minLength = (min: number) => (value?: unknown) => {
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length >= min
  }

  return true
}

const maxLength = (max: number) => (value?: unknown) => {
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length <= max
  }

  return true
}

export function getTicketStatusValidationSchema(status: TicketStatus) {
  const validationSchema = ticketStatusValidation[status]
  if (!validationSchema) {
    return defaultStatusValidation
  }

  return validationSchema
}

export function validateTicketState(status: TicketStatus, state: TicketStatusData) {
  const validationSchema = getTicketStatusValidationSchema(status)

  for (const schemaItemId in validationSchema) {
    const schemaItem = validationSchema[schemaItemId]
    if (!schemaItem) {
      continue
    }

    for (const func of schemaItem) {
      const item = state[schemaItemId as keyof TicketStatusData]
      if (!func(item)) {
        return false
      }
    }
  }

  return true
}

export function getNextTransitionStatus(status: TicketStatus, flow?: FlowType | null) {
  const flowStatuses = TicketStatusFlow[flow || 'BASIC']

  for (let n = 0; n < flowStatuses.length; n += 1) {
    const statuses = flowStatuses[n]

    if (statuses.find((entry) => entry === status)) {
      const nextStatuses = flowStatuses[n + 1]
      if (nextStatuses) {
        return nextStatuses[0]
      }

      break
    }
  }

  return status
}

export function getDeclineStatus(status: TicketStatus) {
  if (
    status === TicketStatus.Submitted ||
    status === TicketStatus.DistributorIdentified ||
    status === TicketStatus.Accepted
  ) {
    return TicketStatus.ReturnCancelled
  }
  if (status === TicketStatus.Processing) {
    return TicketStatus.Declined
  }

  return null
}

const defaultStatusValidation: Record<string, ValidationFunction[] | undefined> = {
  changedAt: [],
  changedBy: [],
  notes: [maxLength(TICKET_CUSTOMER_NOTES_MAX_LENGTH)],
}

const ticketStatusValidation: Partial<Record<TicketStatus, Record<string, ValidationFunction[] | undefined>>> = {
  Submitted: { ...defaultStatusValidation },
  Accepted: {
    ...defaultStatusValidation,
    addressId: [isRequired, minLength(1)],
  },
  DUICFulfillment: {
    ...defaultStatusValidation,
    addressId: [isRequired, minLength(1)],
  },
  DUICShippedPendingReceipt: {
    ...defaultStatusValidation,
    refundOption: [isRequired],
    shippingCompany: [isRequired, maxLength(TICKET_SHIPPING_COMPANY_MAX_LENGTH)],
    trackingNumber: [maxLength(TICKET_TRACKING_NUMBER_MAX_LENGTH)],
    replacementMac: [isRequired],
  },
  Completed: {
    ...defaultStatusValidation,
  },
  InFulfilment: {
    ...defaultStatusValidation,
    failureCategory: [isRequired],
    failureDescription: [isRequired, maxLength(TICKET_FAILURE_DESCRIPTION_MAX_LENGTH)],
    files: [maxLength(1)],
  },
  Shipped: {
    ...defaultStatusValidation,
    shippingCompany: [isRequired, maxLength(TICKET_SHIPPING_COMPANY_MAX_LENGTH)],
    trackingNumber: [maxLength(TICKET_TRACKING_NUMBER_MAX_LENGTH)],
    files: [maxLength(1)],
  },
  Declined: {
    ...defaultStatusValidation,
    notes: [isRequired, maxLength(TICKET_CUSTOMER_NOTES_MAX_LENGTH)],
  },
  ReturnCancelled: {
    ...defaultStatusValidation,
    notes: [isRequired, maxLength(TICKET_CUSTOMER_NOTES_MAX_LENGTH)],
  },
}
