import { useEffect, useMemo } from 'react'

type SubscribeEvent = 'user-updated' | 'table-updated' | 'scroll-to-top'

type ListenerFunc = (arg?: unknown) => void

const listeners: Partial<Record<SubscribeEvent, ListenerFunc[]>> = {}

export function useSignal(event: SubscribeEvent, func: ListenerFunc, funcMemo: unknown[] = []) {
  const memoedFunc = useMemo(() => func, funcMemo)

  useEffect(() => {
    const buffer = listeners[event]
    if (buffer) {
      buffer.push(memoedFunc)
    } else {
      listeners[event] = [func]
    }

    return () => {
      const fromBuffer = listeners[event]
      if (fromBuffer) {
        const index = fromBuffer.indexOf(memoedFunc)
        if (index !== -1) {
          fromBuffer[index] = fromBuffer[fromBuffer.length - 1]
          fromBuffer.pop()
        }
      }
    }
  }, [event, memoedFunc])
}

export function emitSignal(event: SubscribeEvent, arg?: unknown) {
  const buffer = listeners[event]
  if (!buffer) {
    return
  }

  for (const func of buffer) {
    func(arg)
  }
}
