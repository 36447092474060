import type { TicketStatusLabel } from '../../types/brands'

export enum TicketStatus {
  Submitted = 'Submitted', // Ticket is submitted and pending processing
  DistributorIdentified = 'DistributorIdentified', // Ticket is submitted and pending processing
  Accepted = 'Accepted', // Ticket is accepted by merchant
  ReturnCancelled = 'ReturnCancelled', // The item has not been returned withing given period
  Processing = 'Processing', // Item is being processed by the merchant
  InFulfilment = 'InFulfilment', // Request is approved and the replacement is pending shipment
  Declined = 'Declined', // Request is declined
  Shipped = 'Shipped', // The replacement is shipped
  Closed = 'Closed', // Ticket is closed
  Backordered = 'Backordered',
  ReplacementShippedPendingReceipt = 'ReplacementShippedPendingReceipt',
  Expired = 'Expired',
  ShippedPendingReceipt = 'ShippedPendingReceipt',
  Completed = 'Completed',
  InitiatingAdvancement = 'InitiatingAdvancement',
  ReceivedInitiatingAdvancement = 'ReceivedInitiatingAdvancement',
  ReceivedFulfillment = 'ReceivedFulfillment',
  ReceivedBackordered = 'ReceivedBackordered',
  CreditIssued = 'CreditIssued',
  DUICFulfillment = 'DUICFulfillment',
  DUICShippedPendingReceipt = 'DUICShippedPendingReceipt',
}

export type FlowType = 'BASIC' | 'ADVANCED' | 'ADVANCED_DISTRIBUTOR'

export const MaxSelectedShipmentTickets = 50

export const TICKET_STATUS_LABELS: Record<TicketStatus, TicketStatusLabel> = {
  [TicketStatus.Submitted]: 'Pending Approval',
  [TicketStatus.DistributorIdentified]: 'Pending Approval',
  [TicketStatus.Accepted]: 'Approved',
  [TicketStatus.ReturnCancelled]: 'Canceled',
  [TicketStatus.Processing]: 'Testing and Processing',
  [TicketStatus.InFulfilment]: 'Fulfillment',
  [TicketStatus.Declined]: 'Declined',
  [TicketStatus.Shipped]: 'Product Shipped',
  [TicketStatus.Closed]: 'Closed',
  [TicketStatus.Backordered]: 'Backordered',
  [TicketStatus.ReplacementShippedPendingReceipt]: 'Replacement Shipped, Pending Receipt',
  [TicketStatus.Expired]: 'Expired',
  [TicketStatus.ShippedPendingReceipt]: 'Shipped, Pending Receipt',
  [TicketStatus.Completed]: 'Completed',
  [TicketStatus.InitiatingAdvancement]: 'Initiating Advancement',
  [TicketStatus.ReceivedInitiatingAdvancement]: 'Initiating Advancement',
  [TicketStatus.ReceivedFulfillment]: 'Fulfillment',
  [TicketStatus.ReceivedBackordered]: 'Backordered',
  [TicketStatus.CreditIssued]: 'Credit Issued',
  [TicketStatus.DUICFulfillment]: 'Fulfillment',
  [TicketStatus.DUICShippedPendingReceipt]: 'Shipped, Pending Receipt',
} as const

// export const AllStatusLabels: Record<string, TicketStatus[]> = [...new Set(Object.values(TICKET_STATUS_LABELS))]

export const TicketStatusNames: Record<TicketStatus, TicketStatusLabel> = {
  ...TICKET_STATUS_LABELS,
  ReceivedInitiatingAdvancement: 'Received, Initiating Advancement',
}

export type TicketStep =
  | 'Submitted'
  | 'Confirmed'
  | 'PendingReceipt'
  | 'Testing'
  | 'InFulfilment'
  | 'Shipped'
  | 'ReplacementShipped'
  | 'Received'
  | 'Backordered'
  // Advanced steps
  | 'Submission'
  | 'Confirmation'
  | 'Initiation'
  | 'Fulfillment'
  | 'Shipping'
  | 'Receiving'

export const TICKET_STEP_LABELS: Record<TicketStep, string> = {
  Submitted: 'Submitted',
  Confirmed: TICKET_STATUS_LABELS[TicketStatus.DistributorIdentified],
  PendingReceipt: 'Pending Receipt',
  Testing: TICKET_STATUS_LABELS[TicketStatus.Processing],
  InFulfilment: TICKET_STATUS_LABELS[TicketStatus.InFulfilment],
  Shipped: TICKET_STATUS_LABELS[TicketStatus.Shipped],
  ReplacementShipped: TICKET_STATUS_LABELS[TicketStatus.Shipped],
  Received: 'Original Received',
  Backordered: 'Backordered',
  // Advanced steps
  Submission: 'Submitted',
  Confirmation: 'Pending Approval',
  Initiation: 'Initiating Advancement',
  Fulfillment: 'Fulfillment',
  Shipping: 'Product Shipped',
  Receiving: 'Pending Receipt',
}

export const TicketFlowSteps: Record<FlowType, TicketStep[]> = {
  BASIC: ['Submitted', 'Confirmed', 'PendingReceipt', 'Testing', 'InFulfilment', 'Shipped'],
  ADVANCED: ['Submission', 'Confirmation', 'Initiation', 'Fulfillment', 'Shipping', 'Receiving'],
  ADVANCED_DISTRIBUTOR: ['Submission', 'Confirmation', 'Fulfillment', 'Shipping', 'Receiving'],
}

export const TicketStatusFlow: Record<FlowType, TicketStatus[][]> = {
  BASIC: [
    [TicketStatus.Submitted],
    [TicketStatus.Accepted],
    [TicketStatus.Processing],
    [TicketStatus.InFulfilment, TicketStatus.Backordered],
    [TicketStatus.Shipped, TicketStatus.CreditIssued],
  ],
  ADVANCED: [
    [TicketStatus.Submitted],
    [TicketStatus.Accepted],
    [TicketStatus.InitiatingAdvancement],
    [TicketStatus.InFulfilment, TicketStatus.Backordered],
    [TicketStatus.ShippedPendingReceipt],
    [TicketStatus.Completed],
  ],
  ADVANCED_DISTRIBUTOR: [
    [TicketStatus.Submitted],
    [TicketStatus.DUICFulfillment],
    [TicketStatus.DUICShippedPendingReceipt],
    [TicketStatus.Completed],
  ],
}

// In characters
export const TICKET_MAX_UPLOAD_FILES = 5
export const TICKET_SHIPPING_COMPANY_MAX_LENGTH = 25
export const TICKET_TRACKING_NUMBER_MAX_LENGTH = 25
export const TICKET_FAILURE_DESCRIPTION_MAX_LENGTH = 1000

export const TICKET_ATTACHMENT_MAX_FILE_SIZE = 10485760 // 10Mb in bytes
export const TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES = ['image/png', 'image/jpeg']
export const TICKET_ATTACHMENT_VALID_FILE_TYPES = [...TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES, 'application/pdf']
export const VALID_FAE_ATTACHMENTS = [
  ...TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES,
  '',
  'text/x-log',
  'text/plain',
  'text/csv',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pcb',
  'application/pdf',
  'application/zip',
  'application/octet-stream',
  'video/mp4',
]

export const TICKET_EXPIRATION_DAYS = 45
export const TICKET_REVIVE_COUNT_MAX = 1
export const TICKET_CUSTOMER_NOTES_MAX_LENGTH = 400

export const convertToReadableReponseTime = (epoch?: number | null, longNames?: boolean) => {
  if (!epoch) {
    return longNames ? '0 hours' : '0H'
  }

  const days = (epoch / 24) | 0
  const hours = epoch % 24 | 0

  let daysStr = longNames ? ' days' : 'D'
  if (days === 0 && longNames) {
    daysStr = ' day'
  }

  const hoursStr = longNames ? ' hours' : 'H'

  if (days > 0) {
    if (hours > 0) {
      return `${days}${daysStr} ${hours}${hoursStr}`
    }

    return `${days}${daysStr}`
  }

  return `${hours}${hoursStr}`
}

export const DEFAULT_LIMIT = 100

export const CanceledByYou = 'Canceled by you'
