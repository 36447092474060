import { getShippingCompanies, getShippingCompany } from '@shared/shipping'
import { TICKET_SHIPPING_COMPANY_MAX_LENGTH, TICKET_TRACKING_NUMBER_MAX_LENGTH } from '@shared/tickets/constants'
import { Input } from '@ubnt/ui-components'
import { useMemo, useState } from 'react'
import { Dropdown } from '../SprintShared'
import { InputWrapper } from './Input'

export interface ShipmentInfoInputValue {
  shippingCompany: string
  trackingNumber: string
}

export function ShipmentInfoInput({
  value,
  onChange,
}: {
  value: ShipmentInfoInputValue
  onChange: (newValue: ShipmentInfoInputValue) => void
}) {
  const [isOther, setIsOther] = useState(() => !!value.shippingCompany && !getShippingCompany(value.shippingCompany))

  const shippingCompanyOptions = useMemo(() => {
    const shippingCompanies = getShippingCompanies()
    return shippingCompanies.map(({ id, name }) => ({ value: id, label: name }))
  }, [])

  return (
    <>
      <InputWrapper>
        <Dropdown
          width="100%"
          options={shippingCompanyOptions}
          value={isOther ? '__other' : value.shippingCompany}
          onChange={({ value: shippingCompany }) => {
            const isOtherCompany = shippingCompany === '__other'
            setIsOther(isOtherCompany)
            onChange({
              ...value,
              shippingCompany: isOtherCompany ? '' : shippingCompany.slice(0, TICKET_SHIPPING_COMPANY_MAX_LENGTH),
            })
          }}
          data-testid="shipping-company"
          label="Shipping Company"
        />
      </InputWrapper>

      {isOther && (
        <InputWrapper>
          <Input
            autoFocus
            width="100%"
            maxLength={TICKET_SHIPPING_COMPANY_MAX_LENGTH}
            label="Shipping Company"
            value={value.shippingCompany}
            onChange={(e) => onChange({ ...value, shippingCompany: e.currentTarget.value })}
          />
        </InputWrapper>
      )}

      <InputWrapper>
        <Input
          width="100%"
          maxLength={TICKET_TRACKING_NUMBER_MAX_LENGTH}
          label="Tracking Number"
          value={value.trackingNumber}
          onChange={(e) => onChange({ ...value, trackingNumber: e.currentTarget.value })}
          data-testid="tracking-number"
        />
      </InputWrapper>
    </>
  )
}
