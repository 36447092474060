import {
  CustomerTab,
  DetailsTab,
  LogTab,
  MessagingTab,
  TicketProvider,
  Ticket as TicketShared,
  useTicket,
} from '@shared-ui/components/Ticket'
import { ManageTab } from '@shared-ui/components/Ticket/manage'
import { confirm } from '@shared-ui/utils/alerts'
import type { TicketId } from '@shared/types/brands'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDashboardTicketQuery } from './__generated__/Ticket'

export function Ticket({ onClose }: { onClose: () => void }) {
  return (
    <TicketProvider>
      <TicketWrapper onClose={onClose} />
    </TicketProvider>
  )
}

function TicketWrapper({ onClose }: { onClose: () => void }) {
  const { ticketId } = useParams<{
    ticketId: TicketId
  }>()
  const { state } = useTicket()
  const { data, loading, error } = useDashboardTicketQuery({ variables: { id: ticketId } })

  const tabs = useMemo(
    () =>
      [
        { ...DetailsTab, props: { displayCustomer: true } },
        CustomerTab,
        data?.ticket?.supportByUbiquiti === false ? MessagingTab : null,
        LogTab,
        ManageTab,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
      ].filter(<T extends unknown>(tab: T): tab is NonNullable<T> => !!tab),
    [data],
  )

  const wrappedOnClose = () => {
    if (state.unsavedChanges) {
      const leave = confirm('Do you wish to discard Manage tab changes and close this window?')
      if (!leave) {
        return
      }
    }

    onClose()
  }

  return (
    <TicketShared
      tabs={tabs}
      loading={loading}
      error={error}
      ticket={data?.ticket ?? undefined}
      onClose={wrappedOnClose}
    />
  )
}
