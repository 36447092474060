import { MAC_LENGTH, parseMacOrSerialInput, validateMacSerial } from '@shared/lib/validation/mac-serial'
import { CheckmarkIconL } from '@ubnt/icons'
import { Button, cssVariables, Input, Label, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import { useRequest } from '../../hooks'
import { useTicket } from './context/Ticket'

export function ManageMacReplacement() {
  const { state, setReplacementMac } = useTicket()

  const [mac, setMac] = useState('')

  const [validateMac, { data, loading, error }] = useRequest('validateReplacementMac', {
    abortTimeout: 60 * 1000,
    retries: 0,
  })

  function handleMacSearch(newMac: string) {
    setReplacementMac('')

    if (!newMac) {
      return
    }

    void validateMac({
      ticketId: state.ticketId,
      mac: newMac,
    })
      .then(() => {
        setReplacementMac(newMac)
      })
      .catch(() => {
        //
      })
  }

  function handleSetMac(element: HTMLInputElement, newMac: string) {
    setMac(newMac)

    const parsedMac = parseMacOrSerialInput(newMac)
    if (parsedMac.length >= MAC_LENGTH || validateMacSerial(newMac)) {
      element.blur()
    }
  }

  const showCheckmark = !!data

  return (
    <div className="pb-20">
      <Label color="tertiary">Replacement Device MAC ID</Label>

      <div className="relative height-32 flex align-center">
        <Input
          variant="tertiary"
          value={mac}
          onChange={(event) => handleSetMac(event.currentTarget, event.currentTarget.value)}
          disabled={loading}
          inputContainerClassName="my-6 bg-gray border-radius"
          width="100%"
          placeholder="MAC ID"
          onBlur={(event) => void handleMacSearch(event.currentTarget.value)}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              event.currentTarget.blur()
            }
          }}
        />

        <div className="absolute right top height-100 flex flex-basis align-center px-8 pointer-events-none">
          {loading && (
            <>
              <Text size="body" color="tertiary" className="px-8">
                Searching
              </Text>
              <Button loader="loading" />
            </>
          )}

          {showCheckmark && (
            <CheckmarkIconL color={cssVariables['color-success']} variant="fill" width="20" height="20" />
          )}
        </div>
      </div>

      {error && (
        <Text size="body" color="danger">
          {error}
        </Text>
      )}
    </div>
  )
}
